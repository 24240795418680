<template>
  <benchmark-share-frame class="telecom-subcategories-benchmark-asset">
    <telecom-subcategories-benchmark :top3-subcategories="top3Subcategories"/>
  </benchmark-share-frame>
</template>

<script>
import TelecomSubcategoriesBenchmark
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-subcategories-benchmark/TelecomSubcategoriesBenchmark';
import BenchmarkShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/BenchmarkShareFrame';

export default {
  name: 'TelecomSubcategoriesBenchmarkAsset',
  components: {
    BenchmarkShareFrame,
    TelecomSubcategoriesBenchmark,
  },
  props: {
    top3Subcategories: {
      type: Array,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.telecom-subcategories-benchmark-asset {
  background: $riseup_dark_green;
}

</style>
