<template>
  <div class="benchmark-share-frame">
    <div class="real-price-tag"><img src="@/assets/mid-month/real-price-hashtag.svg"></div>
    <slot></slot>
    <div class="tail">
      <div class="logo"><img src="@/assets/logo-vertical.svg"></div>
      <div class="site-url">riseup.co.il</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BenchmarkShareFrame',
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.benchmark-share-frame {
  position: relative;
  width: 500px;
  padding: 30px;

  .real-price-tag {
    position: absolute;
    left: 31px;
    top: 22px;
  }

  .tail {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;
    padding: 35px 0 0;

    .logo img {
      height: 45px;
    }

    .site-url {
      font-family: SimplerPro;
      color: $riseup_white;
      font-size: 18px;
    }
  }
}

</style>
