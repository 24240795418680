<template>
  <div class="telecom-subcategories-benchmark">
    <telecom-headline/>
    <story-title>
      <div>רגע,</div>
      <div>זה טוב או רע?</div>
    </story-title>
    <div class="content">
      <subcategories-benchmark :top3-subcategories="top3Subcategories"/>
    </div>
  </div>
</template>

<script>
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import SubcategoriesBenchmark
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/SubcategoriesBenchmark';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'TelecomSubcategoriesBenchmark',
  mixins: [TransactionDisplayUtils],
  components: {
    TelecomHeadline,
    StoryTitle,
    SubcategoriesBenchmark,
  },
  props: {
    top3Subcategories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.telecom-subcategories-benchmark {
  padding: 15px 30px;
  background-color: $riseup_dark_green;
  color: $riseup_white;

  .content {
    margin-top: 25px;
    padding: 20px 14px 20px;
    background: $riseup_white;
    border-radius: 10px;
  }
}
</style>
