<template>
  <div class="supermarket-eating-out-vs-super">
    <img class="boxing-gloves" src="@/assets/mid-month/supermarket/boxing-gloves.gif">
    <supermarket-headline></supermarket-headline>
    <story-title>ואיך זה התחלק?</story-title>
    <div class="row" v-for="(monthRow, index) in displayedMonths" :key="monthRow.budgetDate" :class="{ 'first-row': index === 0}">
      <div class="month">{{ monthName(monthRow.budgetDate) }}</div>
      <div class="content">
        <div class="eating-out-label">
          <img class="trophy" v-if="supermarketPercent(monthRow) < eatingOutPercent(monthRow)" src="@/assets/mid-month/supermarket/trophy.gif">
          <div class="text">אוכל בחוץ</div>
          <div class="amount">{{ formatAmountWithFractionAndCurrency(monthRow.eatingOut) }}</div>
        </div>
        <div class="bar empty" v-if="monthRow.eatingOut + monthRow.supermarket === 0">0%</div>
        <div class="bar" v-else>
          <div class="eating-out-static-bar" :class="{ empty: eatingOutPercent(monthRow) === 0 }"></div>
          <div class="dynamic-bar">
            <div class="eating-out-bar" :style="{ width: `${eatingOutPercent(monthRow)}%` }">
              <div class="text" v-if="eatingOutPercent(monthRow) > 0 && eatingOutPercent(monthRow) < 100">
                {{ eatingOutPercent(monthRow) }}%
              </div>
              <div class="text100percent" v-if="eatingOutPercent(monthRow) === 100">100% אוכל בחוץ</div>
            </div>
            <div class="supermarket-bar" v-if="supermarketPercent(monthRow) > 0">
              <div class="text" v-if="supermarketPercent(monthRow) < 100">
                {{ supermarketPercent(monthRow) }}%
              </div>
              <div class="text100percent" v-if="supermarketPercent(monthRow) === 100">100% סופר</div>
            </div>
          </div>
          <div class="supermarket-static-bar" :class="{ empty: supermarketPercent(monthRow) === 0 }"></div>
        </div>
        <div class="super-label">
          <img class="trophy" v-if="supermarketPercent(monthRow) > eatingOutPercent(monthRow)" src="@/assets/mid-month/supermarket/trophy.gif">
          <div class="text">סופר</div>
          <div class="amount">{{ formatAmountWithFractionAndCurrency(monthRow.supermarket) }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash';
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import cashflowHistory from '@/store/utilities/cashflow-history';
import * as supermarketTransactionsUtils from '../common/supermarketTransactionsUtils';

export default {
  name: 'SupermarketEatingOutVsSuper',
  mixins: [TransactionDisplayUtils],
  components: {
    StoryTitle,
    SupermarketHeadline,
  },
  props: {
    cashflows: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  computed: {
    months() {
      const currentBudgetDate = cashflowHistory.getCurrentBudgetDate();
      return [
        this.createMonthData(cashflowHistory.getPrevBudgetDate(currentBudgetDate)),
        this.createMonthData(cashflowHistory.getPrevBudgetDate(currentBudgetDate, 2)),
        this.createMonthData(cashflowHistory.getPrevBudgetDate(currentBudgetDate, 3)),
      ];
    },
    displayedMonths() {
      return this.step === 2 ? this.months : this.months.slice(0, 1);
    },
  },
  methods: {
    eatingOutPercent(monthRow) {
      return _.round((monthRow.eatingOut / (monthRow.eatingOut + monthRow.supermarket)) * 100, 0);
    },
    supermarketPercent(monthRow) {
      return 100 - this.eatingOutPercent(monthRow);
    },
    monthName(budgetDate) {
      return cashflowHistory.getFullMonthName(budgetDate);
    },
    createMonthData(budgetDate) {
      return {
        budgetDate,
        eatingOut: supermarketTransactionsUtils.calcMonthlyEatingOutExpense(this.cashflows, budgetDate),
        supermarket: supermarketTransactionsUtils.calcMonthlySuperExpense(this.cashflows, budgetDate),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.supermarket-eating-out-vs-super {
  position: relative;
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;

  .boxing-gloves {
    position: absolute;
    top: 26px;
    left: 65px;
    height: 94px;
  }

  .row {
    margin-top: 45px;
    &.first-row {
      margin-top: 25px;
    }
    .month {
      font-family: PauzaBold;
      text-align: center;
      font-size: 24px;
      margin-bottom: 6px;
      font-weight: bold;
    }
    .content {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      .bar {
        display: flex;
        height: 48px;
        line-height: 48px;
        flex-grow: 1;
        font-size: 18px;
        color: $riseup_dark_blue;

        &.empty {
          border: 1px solid $riseup_white;
          color: $riseup_white;
          justify-content: center;
          border-radius: 25px;
        }

        .eating-out-static-bar {
          border-radius: 0 25px 25px 0;
          background-color: $riseup_orange;
          width: 35px;
          // This is done because of a strange line on iPhones that happened between the supermarket and the eating out
          margin-left: -1px;
          &.empty {
            background-color: $riseup_yellow;
          }
        }
        .supermarket-static-bar {
          border-radius: 25px 0 0 25px;
          background-color: $riseup_yellow;
          width: 35px;
          // This is done because of a strange line on iPhones that happened between the supermarket and the eating out
          margin-right: -1px;
          &.empty {
            background-color: $riseup_orange;
          }
        }

        .dynamic-bar {
          display: flex;
          flex-grow: 1;
          .eating-out-bar {
            position: relative;
            background-color: $riseup_orange;
            text-align: left;
            .text {
              position: absolute;
              left: 5px;
            }
          }
          .supermarket-bar {
            position: relative;
            flex-grow: 1;
            background-color: $riseup_yellow;
            .text {
              position: absolute;
              right: 5px;
            }
          }
          .text100percent {
            text-align: center;
          }
        }
      }
      .eating-out-label {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 64px;
        padding-left: 7px;
        line-height: 14px;
        text-align: left;
        .text {
          font-size: 12px;
          color: $riseup_orange;
        }
        .trophy {
          position: absolute;
          height: 60px;
          top: -50px;
        }
      }
      .super-label {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 64px;
        padding-right: 7px;
        line-height: 14px;
        .text {
          font-size: 12px;
          color: $riseup_yellow;
        }
        .trophy {
          position: absolute;
          height: 70px;
          top: -60px;
        }
      }
    }

  }
}
</style>
