<template>
  <div class="supermarket-top-three">
    <supermarket-headline></supermarket-headline>
    <story-title>איפה הוצאת הכי הרבה ב{{lastMonthName}}?</story-title>
    <div class="businesses">
      <div class="business-row">
        <supermarket-top-business color="pink" :times="businessRows[0].times"
                                  :amount="businessRows[0].sum" :business-name="businessRows[0].businessName"></supermarket-top-business>
      </div>
      <div v-if="businessRows.length > 1" class="business-row">
        <supermarket-top-business color="orange" :times="businessRows[1].times"
                                  :amount="businessRows[1].sum" :business-name="businessRows[1].businessName"></supermarket-top-business>
      </div>
      <div v-if="businessRows.length > 2" class="business-row">
        <supermarket-top-business color="yellow" :times="businessRows[2].times"
                                  :amount="businessRows[2].sum" :business-name="businessRows[2].businessName"></supermarket-top-business>
      </div>
      <img class="shopping-cart" src="@/assets/mid-month/supermarket/shopping-cart.gif">
    </div>
  </div>
</template>

<script>
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import SupermarketTopBusiness
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketTopBusiness';
import cashflowHistory from '@/store/utilities/cashflow-history';
import * as supermarketTransactionsUtils from '../common/supermarketTransactionsUtils';

export default {
  name: 'SupermarketTopThree',
  components: {
    SupermarketHeadline,
    StoryTitle,
    SupermarketTopBusiness,
  },
  props: {
    cashflows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    lastMonthName() {
      return cashflowHistory.getFullMonthName(cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate()));
    },
    businessRows() {
      const lastMonthCashflow = supermarketTransactionsUtils.getCashflowByBudgetDate(
        cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate()),
        this.cashflows,
      );
      return supermarketTransactionsUtils.calcTopThreeSuperBusinesses(lastMonthCashflow);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.supermarket-top-three {
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;

  .businesses {
    margin-top: 43px;
    text-align: center;

    .business-row {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      &:nth-child(1) {
        transform: rotate(1deg);
      }
      &:nth-child(2) {
        transform: rotate(-4deg);
      }
      &:nth-child(3) {
        transform: rotate(-8deg);
        margin-bottom: 0;
      }
    }

    .shopping-cart {
      height: 100px;
    }
  }
}
</style>
