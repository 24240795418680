<template>
  <supermarket-story-share-frame>
    <eating-out-vs-supermarket :cashflows="cashflows" :step="step"/>
  </supermarket-story-share-frame>
</template>

<script>
import SupermarketStoryShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketStoryShareFrame';
import EatingOutVsSupermarket
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/EatingOutVsSupermarket';

export default {
  name: 'EatingOutVsSupermarketAsset',
  components: {
    EatingOutVsSupermarket,
    SupermarketStoryShareFrame,
  },
  props: {
    cashflows: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .supermarket-eating-out-vs-super .boxing-gloves {
  top: 0;
}
</style>
