<template>
  <div class="welcome">
    <telecom-headline/>
    <story-title class="title">
      <div>כל חודש,</div>
      <div>סיפור אחד חדש!</div>
    </story-title>
    <div class="content">
      <div class="party">
        <img class="confetti" :src="require(`@/assets/mid-month/confetti.svg`)"/>
        <div class="party-label">והפעם, הוצאות תקשורת</div>
      </div>
    </div>
    <riseup-button variant="secondary"
                   size="slim"
                   :icon="require('@/assets/mid-month/right-blue.svg')"
                   title="לסיפור שלך"
                   :action="nextPage" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';

export default {
  name: 'Welcome',
  components: {
    TelecomHeadline,
    StoryTitle,
    RiseupButton: BaseUI.RiseupButton,
  },
  methods: {
    nextPage(e) {
      e.stopPropagation();
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.welcome {
  position: relative;
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;

  .title {
    text-align: center;
  }

  .content {
    .party {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 230px;
      margin-top: 46px;
      text-align: center;

      .confetti {
        position: absolute;
      }

      .party-label {
        display: inline-block;
        padding: 0 15px;
        border-radius: 10px;
        background: $riseup_white;
        transform: rotate(-4.72deg);
        font-family: PauzaBold;
        color: $riseup_red;
        font-size: 22px;
        line-height: 48px;
      }
    }
  }

  .riseup-button {
    position: absolute;
    bottom: 22px;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 45px;
    width: 160px;
    border-radius: 23px;
    border: 0;
    color: $riseup_dark_blue;
    &::v-deep {
      .button-content {
        padding: 0 10px;
      }
      .button-icon {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 5px;
        margin-right: -5px;
      }
    }
  }
}
</style>
