<template>
  <benchmark-share-frame class="no-telecom-expenses-asset">
    <no-telecom-expenses/>
  </benchmark-share-frame>
</template>

<script>
import NoTelecomExpenses
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/no-telecom-expenses/NoTelecomExpenses';
import BenchmarkShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/BenchmarkShareFrame';

export default {
  name: 'NoTelecomExpensesAsset',
  components: {
    BenchmarkShareFrame,
    NoTelecomExpenses,
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.no-telecom-expenses-asset {
  background: $riseup_dark_red;
}

</style>
