<template>
  <story-headline icon="☎️" subject="הוצאות תקשורת ודיגיטל" headline="הסיפור שלך ביולי"></story-headline>
</template>

<script>
import StoryHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryHeadline';

export default {
  name: 'TelecomHeadline',
  components: {
    StoryHeadline,
  },
};
</script>

<style lang="scss" scoped>
</style>
