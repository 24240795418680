<template>
  <div class="supermarket-eating-out-vs-super-question">
    <supermarket-headline></supermarket-headline>
    <story-title>אוכל בחוץ VS סופר!</story-title>
    <poll-question class="question" title="איפה לדעתך הוצאת יותר?"
                   color="orange"
                   :initial-selected="eatingOutVsSupermarketPollAnswer"
                   :options="options" selected-option="" @option-clicked="answered"></poll-question>
  </div>
</template>

<script>
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import PollQuestion
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/PollQuestion';
import { mapActions } from 'vuex';
import { calcMonthlyEatingOutExpense, calcMonthlySuperExpense } from '../common/supermarketTransactionsUtils';
import cashflowHistory from '../../../../../../../store/utilities/cashflow-history';

export default {
  name: 'EatingOutVsSupermarketQuestion',
  components: {
    SupermarketHeadline,
    StoryTitle,
    PollQuestion,
  },
  props: {
    eatingOutVsSupermarketPollAnswer: {
      type: String,
      required: true,
    },
    cashflows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    eatingOutVsSuper() {
      const lastBudgetDate = cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate());
      const superTotal = calcMonthlySuperExpense(this.cashflows, lastBudgetDate);
      const eatingOutTotal = calcMonthlyEatingOutExpense(this.cashflows, lastBudgetDate);
      return superTotal - eatingOutTotal;
    },
    options() {
      return [{
        text: 'בסופר - בישלתי מלא בבית  ‍🍳',
        type: this.eatingOutVsSuper >= 0 ? 'true' : 'false',
      }, {
        text: 'במסעדות ובבתי קפה  🍟',
        type: this.eatingOutVsSuper <= 0 ? 'true' : 'false',
      }, {
        text: 'אין לי מושג  🤯',
        type: 'na',
      }];
    },
  },
  methods: {
    ...mapActions('supermarketStory', ['setEatingOutVsSupermarketPollAnswer']),
    answered(answer) {
      this.setEatingOutVsSupermarketPollAnswer(answer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.supermarket-eating-out-vs-super-question {
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;
  .question {
    margin-top: 25px;
  }
}
</style>
