<template>
  <benchmark-share-frame class="telecom-summary-asset">
    <div class="content">
      <telecom-headline/>
      <div class="frame">
        <subcategory-bars :subcategories="subcategories" :low="low" :amount="amount" :with-benchmark="false"/>
        <div class="separator"></div>
        <subcategories-benchmark :top3-subcategories="top3Subcategories"/>
      </div>
    </div>
  </benchmark-share-frame>
</template>

<script>
import BenchmarkShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/BenchmarkShareFrame';
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import SubcategoryBars from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/SubcategoryBars';
import SubcategoriesBenchmark
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/SubcategoriesBenchmark';

export default {
  name: 'TelecomSummaryAsset',
  components: {
    BenchmarkShareFrame,
    TelecomHeadline,
    SubcategoryBars,
    SubcategoriesBenchmark,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    low: {
      type: Number,
      required: true,
    },
    subcategories: {
      type: Array,
      required: true,
    },
    top3Subcategories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.telecom-summary-asset {
  // This is a special color by designers which is not part of the pallet at the time we entered it
  background-color: $riseup_dark_red;
  color: $riseup_white;

  .content {
    padding: 35px 40px 0
  }

  .frame {
    margin-top: 25px;
    padding: 20px 24px;
    background: $riseup_white;
    border-radius: 10px;

    .separator {
      height: 1px;
      background-color: $riseup_gray_0;
      margin: 20px 0;
    }
  }
}
</style>
