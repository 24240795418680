<template>
  <story-viewer
    v-if="!appLoading"
    story-name="SupermarketStory"
    :pages="pages"
    :segment-extra-props="segmentExtraProps"
  ></story-viewer>
</template>
<script>

import StoryViewer from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/StoryViewer';
import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import SupermarketIntro
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketIntro';
import SupermarketBenchmarkAverage
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketBenchmarkAverage';
import SupermarketTopThree
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketTopThree';
import FoodIncomeRatioQuestion
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/FoodIncomeRatioQuestion';
import FoodIncomeRatio
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/FoodIncomeRatio';
import EatingOutVsSupermarketQuestion
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/EatingOutVsSupermarketQuestion';
import EatingOutVsSupermarket
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/EatingOutVsSupermarket';
import SupermarketBenchmarkAverageAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketBenchmarkAverageAsset';
import SupermarketTopThreeAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketTopThreeAsset';
import FoodIncomeRatioQuestionAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/FoodIncomeRatioQuestionAsset';
import FoodIncomeRatioAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/FoodIncomeRatioAsset';
import EatingOutVsSupermarketQuestionAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/EatingOutVsSupermarketQuestionAsset';
import EatingOutVsSupermarketAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/EatingOutVsSupermarketAsset';
import cashflowHistory from '@/store/utilities/cashflow-history';
import {
  calcMonthlyFoodRatioPercent, calcMonthlyIncome, calcMonthlySuperExpense,
  calcTopThreeSuperBusinesses,
  countNonZeroedCategoryInCashflows,
  EATING_OUT_DEFAULT_NAME,
  ECONOMY_DEFAULT_NAME,
  getCashflowByBudgetDate, getLastThreeBudgetDates,
}
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/supermarketTransactionsUtils';
import LowSupermarketExpenses
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/LowSupermarketExpenses';

export default {
  name: 'SupermarketStory',
  components: {
    StoryViewer,
  },
  async created() {
    this.startLoading('SupermarketStory');
    await this.fetchBenchmark();
    this.doneLoading('SupermarketStory');
  },
  computed: {
    ...mapGetters('loader', ['appLoading']),
    ...mapState('supermarketStory', ['benchmark', 'foodRatioPollAnswer', 'eatingOutVsSupermarketPollAnswer']),
    ...mapGetters('supermarketStory', ['cashflows']),
    pages() {
      return _.compact([
        this.createIntroPage(),
        this.shouldShowLowSupermarketExpense ? this.createLowSupermarketExpense() : null,
        ...(this.shouldShowSuperBenchmark ? [
          this.createSupermarketBenchmarkAverage(1),
          this.createSupermarketBenchmarkAverage(2),
          this.createSupermarketBenchmarkAverage(3)] : []),
        this.shouldShowSuperTopThree ? this.createSupermarketTopThree() : null,
        ...(this.shouldShowFoodIncomeRatio ? [this.createFoodIncomeRatioQuestion(), this.createFoodIncomeRatio()] : []),
        ...(this.shouldShowEatingOutVsSupermarket ? [
          this.createEatingOutVsSupermarketQuestion(),
          this.createEatingOutVsSupermarket(1, 7000),
          this.createEatingOutVsSupermarket(2, 10000),
        ] : []),
      ]);
    },
    segmentExtraProps() {
      return {};
    },
    lastMonthBudgetDate() {
      return cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate());
    },
    lastMonthCashflow() {
      return getCashflowByBudgetDate(
        this.lastMonthBudgetDate,
        this.cashflows,
      );
    },
    shouldShowLowSupermarketExpense() {
      return _.chain(getLastThreeBudgetDates())
        .map(budgetDate => calcMonthlySuperExpense(this.cashflows, budgetDate))
        .sum()
        .value() < 200;
    },
    shouldShowSuperBenchmark() {
      return countNonZeroedCategoryInCashflows(ECONOMY_DEFAULT_NAME, this.cashflows) >= 2;
    },
    shouldShowSuperTopThree() {
      const topThree = calcTopThreeSuperBusinesses(this.lastMonthCashflow);
      return topThree.length > 0;
    },
    shouldShowFoodIncomeRatio() {
      if (calcMonthlyIncome(this.cashflows, this.lastMonthBudgetDate) === 0) {
        return false;
      }
      const foodIncomeRatio = calcMonthlyFoodRatioPercent(this.cashflows, this.lastMonthBudgetDate);
      return foodIncomeRatio > 0 && foodIncomeRatio <= 80;
    },
    shouldShowEatingOutVsSupermarket() {
      return countNonZeroedCategoryInCashflows(ECONOMY_DEFAULT_NAME, this.cashflows) > 0
        || countNonZeroedCategoryInCashflows(EATING_OUT_DEFAULT_NAME, this.cashflows) > 0;
    },
  },
  methods: {
    ...mapActions('supermarketStory', ['fetchBenchmark']),
    ...mapActions('loader', ['startLoading', 'doneLoading']),
    createIntroPage() {
      return {
        component: SupermarketIntro,
        props: {},
        config: {},
      };
    },
    createLowSupermarketExpense() {
      return {
        component: LowSupermarketExpenses,
        props: {},
        config: {
          hideFooter: true,
        },
      };
    },
    createSupermarketBenchmarkAverage(step) {
      return {
        component: SupermarketBenchmarkAverage,
        props: {
          benchmark: this.benchmark,
          cashflows: this.cashflows,
          step,
        },
        share: {
          component: SupermarketBenchmarkAverageAsset,
          props: {
            benchmark: this.benchmark,
            cashflows: this.cashflows,
            step,
          },
          segmentExtraProps: {
            page: `SupermarketBenchmarkAverage${step}`,
          },
          filename: 'supermarket-benchmark',
        },
        config: {},
      };
    },
    createSupermarketTopThree() {
      return {
        component: SupermarketTopThree,
        props: {
          cashflows: this.cashflows,
        },
        share: {
          component: SupermarketTopThreeAsset,
          props: {
            cashflows: this.cashflows,
          },
          segmentExtraProps: {
            page: 'SupermarketTopThree',
          },
          filename: 'top3',
        },
        config: {
          time: 9000,
        },
      };
    },
    createFoodIncomeRatioQuestion() {
      return {
        component: FoodIncomeRatioQuestion,
        props: {
          benchmark: this.benchmark,
          cashflows: this.cashflows,
          foodRatioPollAnswer: this.foodRatioPollAnswer,
        },
        share: {
          component: FoodIncomeRatioQuestionAsset,
          props: {
            benchmark: this.benchmark,
            cashflows: this.cashflows,
            foodRatioPollAnswer: this.foodRatioPollAnswer,
          },
          segmentExtraProps: {
            page: 'FoodIncomeRatioQuestion',
          },
          filename: 'foodoutofincome-question',
        },
        config: {
          time: 12000,
        },
      };
    },
    createFoodIncomeRatio() {
      return {
        component: FoodIncomeRatio,
        props: {
          cashflows: this.cashflows,
        },
        share: {
          component: FoodIncomeRatioAsset,
          props: {
            cashflows: this.cashflows,
          },
          segmentExtraProps: {
            page: 'FoodIncomeRatio',
          },
          filename: 'foodoutofincome',
        },
        config: {},
      };
    },
    createEatingOutVsSupermarketQuestion() {
      return {
        component: EatingOutVsSupermarketQuestion,
        props: {
          eatingOutVsSupermarketPollAnswer: this.eatingOutVsSupermarketPollAnswer,
          cashflows: this.cashflows,
        },
        share: {
          component: EatingOutVsSupermarketQuestionAsset,
          props: {
            eatingOutVsSupermarketPollAnswer: this.eatingOutVsSupermarketPollAnswer,
            cashflows: this.cashflows,
          },
          segmentExtraProps: {
            page: 'EatingOutVsSupermarketQuestion',
          },
          filename: 'eatingoutvssupermarket-question',
        },
        config: {
          times: 8000,
        },
      };
    },
    createEatingOutVsSupermarket(step, time) {
      return {
        component: EatingOutVsSupermarket,
        props: {
          cashflows: this.cashflows,
          step,
        },
        share: {
          component: EatingOutVsSupermarketAsset,
          props: {
            cashflows: this.cashflows,
            step,
          },
          segmentExtraProps: {
            page: `EatingOutVsSupermarket${step}`,
          },
          filename: 'eatingoutvssupermarket',
        },
        config: {
          time,
        },
      };
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.story-selector {}
</style>
