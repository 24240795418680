<template>
  <div class="next-story-poll">
    <telecom-headline/>
    <story-title>
      <div>על מה כדאי שנדבר בחודש הבא?</div>
    </story-title>
    <div class="content">
      <div class="answers">
        <div class="bullet" v-for="(option, index) in options" :key="option"
             :class="{ selected: isSelected(option) }"
             v-on:click.stop="bulletClicked(option)">
          <div class="index">
            <img :src="require('@/assets/icons/check_mark.svg')" v-if="isSelected(option)">
            <span v-else>{{ index + 1 }}</span>
          </div>
          <div class="text">{{ option }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'NextStoryPoll',
  components: {
    TelecomHeadline,
    StoryTitle,
  },
  computed: {
    ...mapState('benchmark', ['nextStoryPollAnswer']),
    options() {
      return ['אוכל בחוץ', 'ביטוחים', 'רכב'];
    },
  },
  methods: {
    ...mapActions('benchmark', ['setNextStoryPollAnswer']),
    isSelected(option) {
      return option === this.nextStoryPollAnswer;
    },
    bulletClicked(option) {
      if (this.nextStoryPollAnswer !== option) {
        Segment.trackUserInteraction('TelecomStoryNextStoryPollAnswerClicked', { answer: option });
        this.setNextStoryPollAnswer(option);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.next-story-poll {
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;

  .content {
    .answers {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .bullet {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 72px;
        margin-bottom: 20px;
        background-color: $riseup_white;
        border-radius: 36px;

        &.selected {
          border: 3px solid $riseup_green;
          background-color: $riseup_light_green;
          margin-right: -3px;

          .index {
            background-color: $riseup_green;
          }

          .text {
            font-weight: bold;
          }
        }

        .index {
          height: 48px;
          width: 48px;
          line-height: 50px;
          margin: 12px;
          text-align: center;
          background-color: $riseup_blue;
          border-radius: 24px;
          font-size: 26px;
          font-weight: bold;
        }

        .text {
          font-size: 18px;
          line-height: 20px;
          color: $riseup_black;
        }
      }
    }
  }
}
</style>
