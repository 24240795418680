<template>
  <div class="story-headline">
    <div class="icon">
      <div>{{ icon }}</div>
    </div>
    <div class="headline">
      <div>{{ headline }}</div>
      <div class="story-subject">{{ subject }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoryHeadline',
  props: {
    headline: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.story-headline {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
    width: 50px;
    background: RGBA(255, 255, 255, 0.7);
    border-radius: 25px;
    text-align: center;
  }

  .headline {
    margin-right: 15px;
    font-size: 16px;
    line-height: 18px;
    .story-subject {
      font-weight: bold;
    }
  }
}
</style>
