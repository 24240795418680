<template>
  <div class="supermarket-intro">
    <supermarket-headline></supermarket-headline>
    <story-title class="title">
      <div>כל חודש,</div>
      <div>סיפור אחד חדש!</div>
    </story-title>
    <div class="content">
      <div class="party">
        <img class="confetti" :src="require(`@/assets/mid-month/confetti.svg`)"/>
        <div class="party-label party-label-1">והפעם, לקראת החגים</div>
        <div class="party-label party-label-2">הוצאות סופר</div>
      </div>
    </div>
    <div class="next-story-page">
      <div>קליק לסיפור שלך</div>
      <img src="@/assets/mid-month/supermarket/next-story-page-arrow.svg">
    </div>
  </div>
</template>

<script>
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';

export default {
  name: 'SupermarketIntro',
  components: {
    SupermarketHeadline,
    StoryTitle,
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.supermarket-intro {
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;
  position: relative;

  .title {
    text-align: center;
  }

  .content {
    .party {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 230px;
      margin-top: 46px;
      text-align: center;
      font-family: PauzaBold;
      font-size: 22px;
      line-height: 48px;

      .confetti {
        position: absolute;
      }

      .party-label {
        position: absolute;
        padding: 0 15px;
        border-radius: 10px;
        background: $riseup_white;
      }

      .party-label-1 {
        top: 80px;
        transform: rotate(12.72deg);
        color: $riseup_dark_blue;
      }

      .party-label-2 {
        top: 130px;
        transform: rotate(-4.95deg);
        color: $riseup_orange;
      }
    }
  }
  .next-story-page {
    position: absolute;
    color: $riseup_white;
    line-height: 18px;
    font-size: 16px;
    bottom: 118px;
    right: 19px;
    img {
      position: absolute;
      top: 22px;
      left: 42px;
    }
  }
}
</style>
