<template>
  <div class="low-supermarket-expense">
    <supermarket-headline></supermarket-headline>
    <story-title>
      <div>בתזרים שלך יש מעט</div>
      <div>הוצאות סופר</div>
      <div>בחודשים האחרונים...</div>
    </story-title>
    <div class="content">כדאי לבדוק אם יש הוצאות סופר בקטגוריות אחרות ולהזיז אותן - הסיפור שלך יתעדכן.</div>
  </div>
</template>

<script>
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';

export default {
  name: 'LowSupermarketExpense',
  components: {
    SupermarketHeadline,
    StoryTitle,
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.low-supermarket-expense {
  padding: 15px 29px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;
  .content {
    margin-top: 22px;
    line-height: 22px;
  }
}
</style>
