<template>
  <supermarket-story-share-frame class="share-asset">
    <supermarket-top-three :cashflows="cashflows"/>
  </supermarket-story-share-frame>
</template>

<script>
import SupermarketStoryShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketStoryShareFrame';
import SupermarketTopThree
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketTopThree';

export default {
  name: 'SupermarketTopThreeAsset',
  components: {
    SupermarketTopThree,
    SupermarketStoryShareFrame,
  },
  props: {
    cashflows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
