<template>
  <story-viewer
    story-name="supermarket-teaser"
    :pages="pages"
  ></story-viewer>
</template>
<script>

import StoryViewer from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/StoryViewer';
import SupermarketTeaser
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketTeaser';

export default {
  name: 'SupermarketStoryTeaser',
  components: {
    StoryViewer,
  },
  data() {
    return {
      pages: [
        {
          component: SupermarketTeaser,
          props: {},
          config: {
            hideFooter: true,
            time: 30000,
          },
        },
      ],
    };
  },
};

</script>

<style lang="scss" scoped>
</style>
