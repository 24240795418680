<template>
  <benchmark-share-frame class="telecom-benchmark-asset">
    <telecom-benchmark :subcategories="subcategories" :low="low" :amount="amount" />
  </benchmark-share-frame>
</template>

<script>
import TelecomBenchmark
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-benchmark/TelecomBenchmark';
import BenchmarkShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/BenchmarkShareFrame';

export default {
  name: 'TelecomBenchmarkAsset',
  components: {
    BenchmarkShareFrame,
    TelecomBenchmark,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    low: {
      type: Number,
      required: true,
    },
    subcategories: {
      type: Array,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.telecom-benchmark-asset {
  background: $riseup_dark_red;
}

</style>
