<template>
  <div class="telecom-benchmark">
    <telecom-headline/>
    <story-title>
      <span>הוצאות תקשורת ודיגיטל:</span>
      <span>{{ formatAmountWithFractionAndCurrency(amount) }}</span>
    </story-title>
    <div class="content">
      <subcategory-bars :subcategories="subcategories" :low="low" :amount="amount"/>
    </div>
  </div>
</template>

<script>
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import SubcategoryBars from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/SubcategoryBars';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'TelecomBenchmark',
  mixins: [TransactionDisplayUtils],
  components: {
    TelecomHeadline,
    StoryTitle,
    SubcategoryBars,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    low: {
      type: Number,
      required: true,
    },
    subcategories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.telecom-benchmark {
  padding: 15px 30px;
  background-color: $riseup_dark_red;
  color: $riseup_white;

  .content {
    margin-top: 25px;
    padding: 20px 24px;
    background: $riseup_white;
    border-radius: 10px;
  }
}
</style>
