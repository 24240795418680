<template>
  <div class="subcategory-bars">
    <div class="content-title">כמה שילמת ולמי?</div>
    <div class="graph">
      <div class="category-names">
        <div class="name" v-for="category in categories" :key="category.name">{{ category.name }}</div>
      </div>
      <div class="bars-and-amounts">
        <div class="bar-line" v-for="category in categories" :key="category.name">
          <div class="bar" :style="{ 'flex-grow': category.barRatio }"></div>
          <div class="amount">{{ formatAmountWithFractionAndCurrency(category.amount) }}</div>
        </div>
      </div>
    </div>
    <template v-if="withBenchmark && categories.length > 1">
      <div class="separator"></div>
      <div class="benchmark" :class="{ good: low <= 40 }">
        <div class="inside-percent">{{ low }}%</div>
        <div class="benchmark-text">מלקוחות RiseUp הוציאו פחות ממך</div>
      </div>
    </template>
  </div>
</template>

<script>
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import * as _ from 'lodash';

export default {
  name: 'SubcategoryBars',
  mixins: [TransactionDisplayUtils],
  props: {
    amount: {
      type: Number,
      required: true,
    },
    low: {
      type: Number,
      required: true,
    },
    subcategories: {
      type: Array,
      required: true,
    },
    withBenchmark: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    categories() {
      const CATEGORIES_TO_SHOW = 4;

      const topCategories = _.chain(this.subcategories)
        .sortBy(c => -c.amount)
        .take(CATEGORIES_TO_SHOW)
        .value();

      const otherCategory = {
        name: 'אחר',
        amount: this.amount - _.sumBy(topCategories, c => c.amount),
      };

      const shownCategories = _.chain(topCategories)
        .concat(otherCategory)
        .sortBy(c => -c.amount)
        .reject(c => c.amount <= 1)
        .value();

      const maxAmount = _.maxBy(shownCategories, c => c.amount).amount;

      return _.map(shownCategories, ({ name, amount }) => {
        return {
          name,
          amount,
          barRatio: amount / maxAmount,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.subcategory-bars {
  color: $riseup_black;

  .content-title {
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .graph {
    display: flex;
    flex-direction: row;
    font-size: 12px;

    .category-names {
      flex-grow: 0;
      text-align: left;
      align-items: center;

      .name {
        height: 26px;
        line-height: 26px;
      }
    }

    .bars-and-amounts {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .bar-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 26px;
        line-height: 26px;

        .bar {
          display: flex;
          height: 16px;
          min-width: 20px;
          margin: 0 7px;
          border-radius: 10px;
        }

        .amount {
          font-weight: bold;
        }

        &:nth-child(1) .bar {
          background-color: $riseup_orange;
        }

        &:nth-child(2) .bar {
          background-color: $riseup_yellow;
        }

        &:nth-child(3) .bar {
          background-color: $riseup_red;
        }

        &:nth-child(4) .bar {
          background-color: $riseup_blue;
        }

        &:nth-child(5) .bar {
          background-color: $riseup_dark_blue;
        }
      }
    }
  }

  .separator {
    height: 1px;
    background-color: $riseup_gray_0;
    margin: 20px 0;
  }

  .benchmark {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: $riseup_danger_red;
    font-weight: bold;

    &.good {
      color: $riseup_green;
    }

    .inside-percent {
      font-size: 32px;
    }

    .benchmark-text {
      margin-right: 6px;
      font-size: 14px;
    }
  }
}
</style>
