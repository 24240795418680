<template>
  <supermarket-story-share-frame>
    <supermarket-benchmark-average :benchmark="benchmark" :cashflows="cashflows" :step="step" :show-back-navigation="false"/>
  </supermarket-story-share-frame>
</template>

<script>
import SupermarketStoryShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketStoryShareFrame';
import SupermarketBenchmarkAverage
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/SupermarketBenchmarkAverage';

export default {
  name: 'SupermarketBenchmarkAverageAsset',
  components: {
    SupermarketBenchmarkAverage,
    SupermarketStoryShareFrame,
  },
  props: {
    benchmark: {
      type: Object,
      required: true,
    },
    cashflows: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
