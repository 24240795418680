<template>
  <supermarket-story-share-frame>
    <eating-out-vs-supermarket-question :eating-out-vs-supermarket-poll-answer="eatingOutVsSupermarketPollAnswer" :cashflows="cashflows"/>
  </supermarket-story-share-frame>
</template>

<script>
import SupermarketStoryShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketStoryShareFrame';
import EatingOutVsSupermarketQuestion
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/EatingOutVsSupermarketQuestion';

export default {
  name: 'EatingOutVsSupermarketQuestionAsset',
  components: {
    EatingOutVsSupermarketQuestion,
    SupermarketStoryShareFrame,
  },
  props: {
    eatingOutVsSupermarketPollAnswer: {
      type: String,
      required: true,
    },
    cashflows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
