<template>
  <div class="poll-question">
    <div class="title" :class="`color-${color}`">{{ title }}</div>
    <div class="options">
      <div v-show="chosenOption" class="option answered" v-for="(option, index) in options" :key="`answered-${option.text}`"
           :class="{ 'right-option': option.type === 'true'}" v-on:click.stop="optionClicked(option)">
        <img v-show="option.type === 'true'" :src="require('@/assets/icons/check-green-icon.svg')">
        <img v-show="chosenOption === option.text && option.type === 'false'" :src="require('@/assets/icons/orange-x.svg')">
        <img v-show="chosenOption === option.text && option.type === 'na'" :src="require('@/assets/icons/question-mark-icon.svg')">
        <div v-show="chosenOption !== option.text && option.type !== 'true'" class="index">
          <div class="number">{{ index + 1 }}</div>
        </div>
        <div class="text">{{ option.text }}</div>
      </div>
      <div class="option" v-show="!chosenOption" v-for="(option, index) in options" :key="`noanswer-${option.text}`"
           v-on:click.stop="optionClicked(option)">
        <div class="index">
          <div class="number">{{ index + 1 }}</div>
        </div>
        <div class="text">{{ option.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';

export default {
  name: 'PollQuestion',
  data() {
    return {
      chosenOption: null,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    initialSelected: {
      type: String,
    },
  },
  created() {
    this.chosenOption = this.initialSelected;
  },
  methods: {
    optionClicked(option) {
      Segment.trackUserInteraction('PollQuestionAnswered');
      if (option.text !== this.chosenOption) {
        this.chosenOption = option.text;
      } else {
        this.chosenOption = '';
      }
      this.$emit('option-clicked', this.chosenOption);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.poll-question {
  border-radius: 15px;
  overflow: hidden;
  background: $riseup_white;
  color: $riseup_black;

  .title {
    color: $riseup_white;
    font-family: PauzaBold;
    font-size: 26px;
    line-height: 45px;
    padding: 5px 24px;

    &.color-pink {
      background: $riseup_red;
    }
    &.color-orange {
      background: $riseup_orange;
    }
  }

  .options {
    padding: 16px 24px 0;
    font-size: 18px;
    .option {
      display: flex;
      margin-bottom: 16px;
      border: 1px solid $riseup_gray_2;
      border-radius: 40px;
      padding: 16px 18px 16px 0;
      .index {
        font-size: 13px;
        font-weight: bold;
        margin-left: 10px;
        border: 1px solid $riseup_black;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 21px;
        width: 21px;
        .number {
          margin-top: 2px;
        }
      }
      .text {
        line-height: 20px;
      }

      &.answered {
        opacity: 0.5;
        &.right-option {
          opacity: 1;
          border-color: $riseup_green;
          font-weight: bold;
        }
        img {
          height: 21px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
