import _ from 'lodash';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import { DateLocales } from '@riseupil/common-utils';
import cashflowNormalizer from '../../../../../../../store/utilities/cashflow-normalizer';
import cashflowHistory from '../../../../../../../store/utilities/cashflow-history';

export const ECONOMY_DEFAULT_NAME = 'סופר';
export const EATING_OUT_DEFAULT_NAME = 'אוכל בחוץ';

export function getTransactionsForCategory(cashflow) {
  const normalizedCashflow = cashflowNormalizer.normalizeCashflow(reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL));
  const requestedTrackingCategory = _.find(normalizedCashflow.trackingCategory, c => c.categoryName === ECONOMY_DEFAULT_NAME)?.categories || [];
  return _.flatMap(requestedTrackingCategory, category => category.transactions);
}

export function calcMonthlySuperExpense(cashflows, budgetDate) {
  return calcMonthlyCategoryExpense(cashflows, budgetDate, ECONOMY_DEFAULT_NAME);
}

export function calcMonthlyEatingOutExpense(cashflows, budgetDate) {
  return calcMonthlyCategoryExpense(cashflows, budgetDate, EATING_OUT_DEFAULT_NAME);
}

export function calcMonthlyCategoryExpense(cashflows, budgetDate, category) {
  const requestedMonthCashflow = getCashflowByBudgetDate(budgetDate, cashflows);
  return requestedMonthCashflow ? calcCategoryExpense(requestedMonthCashflow, category) : 0;
}

export function calcCategoryExpense(cashflow, category) {
  const normalizedCashflow = cashflowNormalizer.normalizeCashflow(reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL));
  return _.find(normalizedCashflow.trackingCategory, c => c.categoryName === category)?.upToNowAmount || null;
}

export function calcMonthlyIncome(cashflows, budgetDate) {
  const requestedCashflow = getCashflowByBudgetDate(budgetDate, cashflows);
  return reactiveCashflow.createCashflow(requestedCashflow, DateLocales.EN_IL).incomeActualSum * -1;
}

export function getCashflowByBudgetDate(budgetDate, cashflows) {
  return _.find(cashflows, cashflow => cashflow.budgetDate === budgetDate);
}

export function calcMonthlyFoodRatioPercent(cashflows, budgetDate) {
  const foodExpense = calcMonthlyEatingOutExpense(cashflows, budgetDate) + calcMonthlySuperExpense(cashflows, budgetDate);
  return Math.round((foodExpense * 100) / calcMonthlyIncome(cashflows, budgetDate));
}

export function calcTopThreeSuperBusinesses(cashflow) {
  const transactions = getTransactionsForCategory(cashflow);
  const sortedBusinessesSums = _.chain(transactions)
    .groupBy('businessName')
    .reduce((businessesSums, transactions, businessName) => [
      ...businessesSums,
      {
        businessName,
        sum: _.sumBy(transactions, t => t.billingAmount),
        times: transactions.length,
      },
    ],
    [])
    .sortBy('sum')
    .reverse()
    .value();
  return sortedBusinessesSums.slice(0, 3);
}

export function countNonZeroedCategoryInCashflows(category, cashflows) {
  return _.chain(getLastThreeBudgetDates())
    .map(budgetDate => (calcMonthlyCategoryExpense(cashflows, budgetDate, category) ? 1 : 0))
    .sum()
    .value();
}

export function getLastThreeBudgetDates() {
  return [
    cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate()),
    cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate(), 2),
    cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate(), 3),
  ];
}
