<template>
  <div class="food-income-ratio-question">
    <supermarket-headline></supermarket-headline>
    <story-title class="title">
      <span>לקוחות שדומים לך, הוציאו</span>
      <span class="percent">{{ similarCustomersMedian }}%</span>
      <span>מההכנסות  על אוכל בחוץ + סופר</span>
    </story-title>
    <poll-question class="question" title="מה דעתך, כמה זה אצלך?"
                   color="pink"
                   :initial-selected="foodRatioPollAnswer"
                   :options="options" selected-option="" @option-clicked="answered"></poll-question>
  </div>
</template>

<script>
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import PollQuestion
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/PollQuestion';
import { mapActions } from 'vuex';
import cashflowHistory from '@/store/utilities/cashflow-history';
import * as supermarketTransactionsUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/supermarketTransactionsUtils';
import _ from 'lodash';

export default {
  name: 'FoodIncomeRatioQuestion',
  components: {
    SupermarketHeadline,
    StoryTitle,
    PollQuestion,
  },
  props: {
    benchmark: {
      type: Object,
      required: true,
    },
    cashflows: {
      type: Array,
      required: true,
    },
    foodRatioPollAnswer: {
      type: String,
      required: true,
    },
  },
  computed: {
    budgetDate() {
      return cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate());
    },
    similarCustomersMedian() {
      const { benchmarks } = this.benchmark;
      const foodIncomeRatioBenchmark = _.find(benchmarks, benchmark => benchmark.name.startsWith('food-vs-income'));
      return Math.round(foodIncomeRatioBenchmark.graph[50]);
    },
    yourPercent() {
      return supermarketTransactionsUtils.calcMonthlyFoodRatioPercent(this.cashflows, this.budgetDate);
    },
    options() {
      const moreThanText = 'יותר מ-';
      const lessThanText = 'פחות מ-';
      const theSameAsText = 'בול אותו הדבר';
      return [{
        text: `${moreThanText}${this.similarCustomersMedian}%`,
        type: this.yourPercent > this.similarCustomersMedian ? 'true' : 'false',
      }, {
        text: theSameAsText,
        type: this.yourPercent === this.similarCustomersMedian ? 'true' : 'false',
      }, {
        text: `${lessThanText}${this.similarCustomersMedian}%`,
        type: this.yourPercent < this.similarCustomersMedian ? 'true' : 'false',
      }];
    },
  },
  methods: {
    ...mapActions('supermarketStory', ['setFoodRatioPollAnswer']),
    answered(answer) {
      this.setFoodRatioPollAnswer(answer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.food-income-ratio-question {
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;
  .title {
    font-size: 40px;
    line-height: 40px;
  }
  .question {
    margin-top: 25px;
  }
  .percent {
    color: $riseup_red;
  }
}
</style>
