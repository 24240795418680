<template>
  <div class="supermarket-story-share-frame">
    <div class="real-price-tag"><img src="@/assets/mid-month/real-price-hashtag.svg"></div>
    <slot></slot>
    <div class="tail">
      <div class="logo"><img src="@/assets/logo-vertical.svg"></div>
      <div class="site-url">riseup.co.il</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SupermarketStoryShareFrame',
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.supermarket-story-share-frame {
  position: relative;
  width: 500px;
  height: 700px;
  padding: 30px 50px;
  background: $riseup_dark_blue;

  // Since canvas doesn't support box shadow, we remove it here
  ::v-deep * {
    box-shadow: none !important;
  }

  .real-price-tag {
    position: absolute;
    z-index: 10;
    left: 31px;
    top: 22px;
  }

  .tail {
    .logo img {
      position: absolute;
      bottom: 30px;
      left: 30px;
      height: 45px;
    }

    .site-url {
      position: absolute;
      bottom: 30px;
      right: 30px;
      font-family: SimplerPro;
      color: $riseup_white;
      font-size: 18px;
    }
  }
}

</style>
