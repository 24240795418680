import { render, staticRenderFns } from "./EatingOutVsSupermarketQuestion.vue?vue&type=template&id=97bd1758&scoped=true&"
import script from "./EatingOutVsSupermarketQuestion.vue?vue&type=script&lang=js&"
export * from "./EatingOutVsSupermarketQuestion.vue?vue&type=script&lang=js&"
import style0 from "./EatingOutVsSupermarketQuestion.vue?vue&type=style&index=0&id=97bd1758&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97bd1758",
  null
  
)

export default component.exports