<template>
  <div class="telecom-summary">
    <div class="internal-padding">
      <div class="frame">
        <div class="real-price-tag"><img src="@/assets/mid-month/real-price-hashtag.svg"></div>
        <telecom-headline/>
        <div class="content">
          <subcategory-bars :subcategories="subcategories" :low="low" :amount="amount" :with-benchmark="false"/>
          <div class="separator"></div>
          <subcategories-benchmark :top3-subcategories="top3Subcategories"/>
        </div>
        <div class="month-sticker"><img :src="require('@/assets/mid-month/month-sticker.svg')"></div>
      </div>
      <div class="household-name">{{ householdName }}</div>
    </div>
  </div>
</template>

<script>
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import SubcategoryBars from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/SubcategoryBars';
import SubcategoriesBenchmark
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/SubcategoriesBenchmark';

export default {
  name: 'TelecomSummary',
  components: {
    TelecomHeadline,
    SubcategoryBars,
    SubcategoriesBenchmark,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    low: {
      type: Number,
      required: true,
    },
    subcategories: {
      type: Array,
      required: true,
    },
    top3Subcategories: {
      type: Array,
      required: true,
    },
    householdName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.telecom-summary {
  // This is a special color by designers which is not part of the pallet at the time we entered it
  background-color: #7D3C73;
  color: $riseup_white;

  .internal-padding {
    padding: 54px 40px 0;
  }

  .frame {
    position: relative;
    padding: 22px;
    background-color: $riseup_dark_red;
    border-radius: 22px 22px 25px;

    .real-price-tag {
      position: absolute;
      left: -12px;
      top: -21px;
    }

    .month-sticker {
      position: absolute;
      bottom: -20px;
      left: -20px;
    }
  }

  .content {
    margin-top: 25px;
    padding: 20px 24px;
    background: $riseup_white;
    border-radius: 10px;
    margin-bottom: -25%;

    transform: scale(0.8);
    width: 125%;
    transform-origin: top right;

    .separator {
      height: 1px;
      background-color: $riseup_gray_0;
      margin: 20px 0;
    }
  }

  .household-name {
    margin-top: 6px;
    color: $riseup_yellow;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }
}
</style>
