<template>
  <div class="subcategories-benchmark">
    <div class="category-line" v-for="category in top3Subcategories" :key="category.name">
      <div class="badge" v-if="category.good">
        <img class="star-icon" :src="require(`@/assets/mid-month/star-green.svg`)"/>
      </div>
      <div class="badge" v-else>
        <img class="star-icon" :src="require(`@/assets/mid-month/star-red.svg`)"/>
      </div>
      <div class="category-details">
        <div class="category-name">{{ category.name }}</div>
        <div class="category-amount">{{ formatAmountWithFractionAndCurrency(category.amount) }}</div>
        <div class="benchmark" :class="{ good: category.good }">
          <span>{{ category.low }}%</span>
          <span>מלקוחות RiseUp משלמים פחות ממך</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'SubcategoriesBenchmark',
  mixins: [TransactionDisplayUtils],
  props: {
    top3Subcategories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.subcategories-benchmark {
  color: $riseup_black;

  .category-line {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    font-size: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .badge {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      transform: rotate(20deg);
      height: 62px;
      width: 62px;

      .star-icon {
        top: 0;
        position: absolute;
      }
    }

    .category-details {
      margin-right: 14px;
      color: $riseup_black;

      .category-name {
        line-height: 16px;
      }

      .category-amount {
        font-size: 26px;
        line-height: 30px;
        font-weight: bold;
      }

      .benchmark {
        font-weight: bold;
        line-height: 16px;
        color: $riseup_danger_red;
        &.good {
          color: $riseup_green;
        }
      }
    }
  }
}
</style>
