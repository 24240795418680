<template>
  <supermarket-story-share-frame>
    <food-income-ratio :cashflows="cashflows"/>
  </supermarket-story-share-frame>
</template>

<script>
import SupermarketStoryShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketStoryShareFrame';
import FoodIncomeRatio
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/FoodIncomeRatio';

export default {
  name: 'FoodIncomeRatioAsset',
  components: {
    FoodIncomeRatio,
    SupermarketStoryShareFrame,
  },
  props: {
    cashflows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
