<template>
  <div class="story-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'StoryTitle',
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.story-title {
  margin-top: 33px;
  font-family: PauzaBold;
  font-size: 46px;
  line-height: 45px;
  color: $riseup_white;
}
</style>
