<template>
  <div class="supermarket-teaser">
    <story-title class="story-title">
      <div>תיכף זה מגיע...</div>
      <div>סיפור הסופר בדרך</div>
    </story-title>
    <div class="count-down">
      <div class="timer">
        <div class="minutes">
          <div class="value">
            <div class="digit">{{ getSinglesDigitFromNumber(dateToCountDownObject.minutes) }}</div>
            <div class="digit">{{ getTensDigitFromNumber(dateToCountDownObject.minutes) }}</div>
          </div>
          <div class="label">דקות</div>
        </div>
        <div class="colon">:</div>
        <div class="hours">
          <div class="value">
            <div class="digit">{{ getSinglesDigitFromNumber(dateToCountDownObject.hours) }}</div>
            <div class="digit">{{ getTensDigitFromNumber(dateToCountDownObject.hours) }}</div>
          </div>

          <div class="label">שעות</div>
        </div>
        <div class="colon">:</div>
        <div class="days">
          <div class="value">
            <div class="digit">{{ getSinglesDigitFromNumber(dateToCountDownObject.days) }}</div>
            <div class="digit">{{ getTensDigitFromNumber(dateToCountDownObject.days) }}</div>
          </div>
          <div class="label">ימים</div>
        </div>
      </div>
      <div class="text">נתראה ממש בקרוב!</div>
    </div>
    <img class="guy-with-shopping-cart" :src="require(`@/assets/mid-month/supermarket/guy-with-shopping-cart.gif`)">
  </div>
</template>

<script>
import moment from 'moment';
import Segment from '@/Segment';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';

export default {
  name: 'SupermarketTeaser',
  components: {
    StoryTitle,
  },
  data() {
    return {
      launchDate: moment('2022-09-19').startOf('day'),
    };
  },
  computed: {
    dateToCountDownObject() {
      return {
        days: this.launchDate.diff(moment(), 'days'),
        hours: moment().endOf('day').diff(moment(), 'hours'),
        minutes: moment().endOf('hour').diff(moment(), 'minutes'),
      };
    },
  },
  created() {
    Segment.trackUserGot('SupermarketStoryTeaserShown');
  },
  methods: {
    getTensDigitFromNumber(number) {
      const numberString = this.numberToTwoDigitsString(number);
      return this.getTensDigitFromNumberString(numberString);
    },
    getTensDigitFromNumberString(numberString) {
      return numberString.split('')[0];
    },
    getSinglesDigitFromNumber(number) {
      const numberString = this.numberToTwoDigitsString(number);
      return this.getSinglesDigitFromNumberString(numberString);
    },
    getSinglesDigitFromNumberString(numberString) {
      return numberString.split('')[1];
    },
    numberToTwoDigitsString(number) {
      return number < 10 ? `0${number}` : number.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.supermarket-teaser {
  padding: 0 30px;
  text-align: center;
  background-color: $riseup_blue;
  color: $riseup_white;

  .story-title {
    margin-top: 120px;
  }

  .count-down {
    margin-top: 33px;
    padding: 20px;
    border-radius: 10px;
    color: $riseup_dark_blue;
    background-color: $riseup_light_blue;
    text-align: center;
    font-size: 26px;
    font-weight: bold;

    .timer {
      display: flex;
      justify-content: center;
      font-size: 47px;

      .colon {
        margin-top: 10px;
        font-weight: bold;
        color: $riseup_white;
      }

      .label {
        font-size: 14px;
        font-weight: normal;
      }

      .value {
        display: flex;
        margin: 6px;

        .digit {
          font-family: PauzaBold;
          margin: 0px 2.5px;
          padding: 9px 5px 0px;
          min-width: 27px;
          border-radius: 5px;
          background-color: $riseup_white;
          color: $riseup_red;
          font-weight: bold;
        }
      }
    }

    .text {
      margin-top: 20px;
      font-family: PauzaBold;
    }
  }
  .guy-with-shopping-cart {
    margin-top: 8px;
    height: 139px;
    position: absolute;
    left: 33px;
  }
}
</style>
