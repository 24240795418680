<template>
  <div class="supermarket-benchmark-average">
    <supermarket-headline></supermarket-headline>
    <story-title>כמה הוצאת בסופר בממוצע?</story-title>
    <div class="average-card">
      <div class="your-average">
        <div class="text">הממוצע שלך</div>
        <price-tag :amount="yourAverage" :color="yourColor"></price-tag>
      </div>
      <div class="like-you-average">
        <div class="text">לקוחות שדומים לך</div>
        <price-tag :amount="similarCustomersAverageMedian" :color="similarCustomersColor"></price-tag>
      </div>
      <div v-if="step === 3" class="above-floating-text">
        <div>לפי הכנסה</div>
        <div>וגודל משק בית</div>
        <img src="@/assets/mid-month/supermarket/average-top-arrow.svg">
      </div>
      <div v-if="step >= 2" class="below-floating-graph">
        <div class="graph">
          <bar-graph class="past-months-bar-graph" :graph-height="53" :bar-values="barValues" :with-sign="false" unit="₪"></bar-graph>
        </div>
        <img src="@/assets/mid-month/supermarket/average-bottom-arrow.svg">
      </div>
      <div class="tomato"><img src="@/assets/mid-month/supermarket/tomato.gif"></div>
      <div class="eggs"><img src="@/assets/mid-month/supermarket/eggs.gif"></div>
    </div>
    <div class="previous-story-page" v-if="showBackNavigation">
      <div>קליק אחורה</div>
      <img src="@/assets/mid-month/supermarket/next-story-page-arrow.svg">
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import PriceTag
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/PriceTag';
import * as supermarketTransactionsUtils from '../common/supermarketTransactionsUtils';
import cashflowHistory from '../../../../../../../store/utilities/cashflow-history';

export default {
  name: 'SupermarketBenchmarkAverage',
  components: {
    SupermarketHeadline,
    StoryTitle,
    PriceTag,
    BarGraph: BaseUI.BarGraph,
  },
  props: {
    benchmark: {
      type: Object,
      required: true,
    },
    cashflows: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    showBackNavigation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    similarCustomersAverageMedian() {
      const { benchmarks } = this.benchmark;
      const supermarketBenchmark = _.find(benchmarks, benchmark => benchmark.name.startsWith('supermarket'));
      return supermarketBenchmark.graph[50];
    },
    yourAverage() {
      return _.meanBy(
        this.cashflows,
        cashflow => supermarketTransactionsUtils.calcCategoryExpense(cashflow, supermarketTransactionsUtils.ECONOMY_DEFAULT_NAME),
      );
    },
    similarCustomersColor() {
      return this.yourAverage >= this.similarCustomersAverageMedian ? 'green' : 'red';
    },
    yourColor() {
      return this.yourAverage <= this.similarCustomersAverageMedian ? 'green' : 'red';
    },
    barValues() {
      const currentBudgetDate = cashflowHistory.getCurrentBudgetDate();

      return [
        this.createGraphBar(cashflowHistory.getPrevBudgetDate(currentBudgetDate)),
        this.createGraphBar(cashflowHistory.getPrevBudgetDate(currentBudgetDate, 2)),
        this.createGraphBar(cashflowHistory.getPrevBudgetDate(currentBudgetDate, 3)),
      ];
    },
  },
  methods: {
    createGraphBar(budgetDate) {
      return {
        name: cashflowHistory.getMonthName(budgetDate),
        value: supermarketTransactionsUtils.calcMonthlySuperExpense(this.cashflows, budgetDate),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.supermarket-benchmark-average {
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;
  position: relative;

  .average-card {
    margin-top: 43px;
    padding: 20px 20px 48px;
    background-color: $riseup_light_blue;
    color: $riseup_dark_blue;
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    position: relative;

    .your-average {
      .text {
        font-size: 14px;
        margin-bottom: 15px;
      }
      .price-tag {
        transform: rotate(5deg);
      }
    }

    .like-you-average {
      .text {
        font-size: 14px;
        margin-bottom: 25px;
      }
      .price-tag {
        transform: rotate(-5deg);
      }
    }

    .above-floating-text {
      position: absolute;
      color: $riseup_white;
      font-weight: normal;
      line-height: 18px;
      font-size: 14px;
      top: -48px;
      left: 42px;
      img {
        position: absolute;
        top: -7px;
        left: -24px;
      }
    }

    .below-floating-graph {
      position: absolute;
      color: $riseup_white;
      font-weight: normal;
      bottom: -180px;
      right: 30px;
      .graph {
        position: absolute;
        height: 100px;
        width: 150px;
        bottom: 22px;
        left: 30px;
        transform: rotate(-7deg);
        &::v-deep {
          .bar-graph {
            margin: 0;
            .graph-drawing {
              .bars {
                color: $riseup_white;
                &.positive .bar {
                  background-color: $riseup_light_blue;
                }
              }
              .line {
                background-color: $riseup_blue;
              }
            }
            .month-names .month-name {
              color: $riseup_white;
            }
          }
        }
      }
    }

    .tomato {
      position: absolute;
      top: -39px;
      right: -24px;
      img {
        height: 62px;
      }
    }

    .eggs {
      position: absolute;
      bottom: -60px;
      left: -20px;
      img {
        height: 116px;
      }
    }
  }
  .previous-story-page {
    position: absolute;
    color: $riseup_white;
    line-height: 18px;
    font-size: 16px;
    bottom: 118px;
    left: 34px;
    img {
      position: absolute;
      transform: scaleX(-1);
      top: 22px;
      right: 40px;
    }
  }
}
</style>
