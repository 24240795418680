<template>
  <div class="no-telecom-expenses">
    <div class="header">
      <telecom-headline/>
      <story-title>
        <div>
          <span>הפעם זה סיפור קצר...</span>
          <span>הוצאת</span>
          <span class="amount">0 ₪</span>
          <span>על תקשורת ודיגיטל.</span>
        </div>
        <div class="subtitle">המערכת לא זיהתה בהוצאות הקבועות שלך הוצאות תקשורת ודיגיטל</div>
        <div>
          <span>לקוחות רייזאפ הוציאו בממוצע</span>
          <span class="amount">264 ₪</span>
          <span>בחודש על תקשורת ודיגיטל.</span>
        </div>
        <div class="subtitle last-subtitle">רוצה לבחור מה יהיה הסיפור הבא?</div>
      </story-title>
      <img class="next-arrow" :src="require('@/assets/mid-month/next-arrow-cool.svg')">
    </div>
  </div>
</template>

<script>
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';

export default {
  name: 'NoTelecomExpenses',
  components: {
    TelecomHeadline,
    StoryTitle,
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.no-telecom-expenses {
  background-color: $riseup_dark_red;
  color: $riseup_white;

  .header {
    padding: 0 30px;

    ::v-deep .story-title {
      line-height: 40px;
    }

    .subtitle {
      font-family: SimplerPro;
      font-size: 16px;
      line-height: 22px;
      margin: 12px 0 34px;

      &.last-subtitle {
        margin-bottom: 0;
      }
    }

    .amount {
      color: $riseup_red;
    }

    .next-arrow {
      margin-right: 10px;
    }
  }
}
</style>
