<template>
  <div class="food-income-ratio">
    <supermarket-headline></supermarket-headline>
    <div class="content">
      <story-title>אוכל בחוץ + סופר =</story-title>
      <div class="percent">{{ percent }}%</div>
      <div class="subtitle">
        <span>מההכנסות שלך ב</span><span>{{ monthName }}</span>
      </div>
      <img class="bowl" src="@/assets/mid-month/supermarket/food-bowl.gif">
    </div>
  </div>
</template>

<script>
import SupermarketHeadline
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';
import cashflowHistory from '@/store/utilities/cashflow-history';
import * as supermarketTransactionsUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/supermarketTransactionsUtils';

export default {
  name: 'FoodIncomeRatio',
  components: {
    SupermarketHeadline,
    StoryTitle,
  },
  props: {
    cashflows: {
      type: Array,
      required: true,
    },
  },
  computed: {
    budgetDate() {
      return cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate());
    },
    monthName() {
      return cashflowHistory.getFullMonthName(this.budgetDate);
    },
    percent() {
      return supermarketTransactionsUtils.calcMonthlyFoodRatioPercent(this.cashflows, this.budgetDate);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.food-income-ratio {
  padding: 15px 30px;
  background-color: $riseup_dark_blue;
  color: $riseup_white;
  .content {
    font-family: PauzaBold;
    text-align: center;
    .percent {
      color: $riseup_red;
      font-size: 190px;
      line-height: 190px;
      margin-bottom: -20px;
    }
    .subtitle {
      font-size: 46px;
      line-height: 45px;
    }
    .bowl {
      height: 160px;
    }
  }
}
</style>
