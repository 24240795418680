<template>
  <div class="supermarket-top-business" :class="`color-${color}`">
    <div class="business-name">
      <img v-if="color === 'orange'" class="location-icon" src="@/assets/mid-month/supermarket/location-orange.svg">
      <img v-if="color === 'pink'" class="location-icon" src="@/assets/mid-month/supermarket/location-pink.svg">
      <img v-if="color === 'yellow'" class="location-icon" src="@/assets/mid-month/supermarket/location-yellow.svg">
      <div class="name">{{ businessName }}</div>
      <div class="amount">{{ formatAmountWithFractionAndCurrency(amount) }}</div>
    </div>
    <div class="times">
      <div>{{ times }}</div>
      <div class="text">פעמים</div>
    </div>
  </div>
</template>

<script>
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'SupermarketTopBusiness',
  mixins: [TransactionDisplayUtils],
  props: {
    businessName: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    times: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.supermarket-top-business {
  display: flex;
  color: $riseup_dark_blue;
  border-radius: 6px;

  &.color-orange {
    background: $riseup_orange;
  }
  &.color-pink {
    background: $riseup_red;
  }
  &.color-yellow {
    background: $riseup_yellow;
  }

  .location-icon {
    margin-left: 10px;
  }

  .business-name {
    display: flex;
    align-items: center;
    background: $riseup_white;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 20px;
    max-width: 290px;

    .amount {
      font-weight: bold;
      margin-right: 8px;
      flex-shrink: 0;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }

  .times {
    font-weight: bold;
    text-align: center;
    padding: 8px;
    .text {
      font-size: 12px;
    }
  }
}
</style>
