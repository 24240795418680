<template>
  <div class="improvements">
    <div class="header">
      <telecom-headline/>
      <story-title>
        <div>מה אפשר לעשות?</div>
        <div class="subtitle">
          <span>660</span>
          <span>מלקוחות רייזאפ צמצמו את הוצאות התקשורת ביותר</span>
          <span>מ-50 ₪</span>
          <span>בחודשים האחרונים</span>
        </div>
      </story-title>
    </div>
    <div class="content">
      <img :src="require(`@/assets/mid-month/testimonials.png`)"/>
    </div>
  </div>
</template>

<script>
import TelecomHeadline from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/TelecomHeadline';
import StoryTitle from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/StoryTitle';

export default {
  name: 'Improvements',
  components: {
    TelecomHeadline,
    StoryTitle,
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.improvements {
  background-color: $riseup_dark_red;
  color: $riseup_white;

  .header {
    padding: 0 30px;

    .subtitle {
      font-family: SimplerPro;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .content {
    display: flex;
    justify-content: space-around;
    margin-top: 46px;
    padding: 0 20px;
    img {
      width: 100%;
    }
  }
}
</style>
