import { render, staticRenderFns } from "./LowSupermarketExpenses.vue?vue&type=template&id=05096903&scoped=true&"
import script from "./LowSupermarketExpenses.vue?vue&type=script&lang=js&"
export * from "./LowSupermarketExpenses.vue?vue&type=script&lang=js&"
import style0 from "./LowSupermarketExpenses.vue?vue&type=style&index=0&id=05096903&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05096903",
  null
  
)

export default component.exports