<template>
  <supermarket-story-share-frame>
    <food-income-ratio-question :food-ratio-poll-answer="foodRatioPollAnswer" :cashflows="cashflows" :benchmark="benchmark"/>
  </supermarket-story-share-frame>
</template>

<script>
import SupermarketStoryShareFrame
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/common/SupermarketStoryShareFrame';
import FoodIncomeRatioQuestion
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/pages/FoodIncomeRatioQuestion';

export default {
  name: 'FoodIncomeRatioQuestionAsset',
  components: {
    FoodIncomeRatioQuestion,
    SupermarketStoryShareFrame,
  },
  props: {
    benchmark: {
      type: Object,
      required: true,
    },
    cashflows: {
      type: Array,
      required: true,
    },
    foodRatioPollAnswer: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
