<template>
  <div v-if="!appLoading" class="telecom-story">
    <story-viewer :pages="pages" story-name="TelecomStory" :segment-extra-props="segmentExtraProps"></story-viewer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import StoryViewer from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/StoryViewer';
import Welcome from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/Welcome';
import NoTelecomExpenses
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/no-telecom-expenses/NoTelecomExpenses';
import NoTelecomExpensesAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/no-telecom-expenses/NoTelecomExpensesAsset';
import TelecomBenchmark from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-benchmark/TelecomBenchmark';
import TelecomBenchmarkAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-benchmark/TelecomBenchmarkAsset';
import TelecomSubcategoriesBenchmark
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-subcategories-benchmark/TelecomSubcategoriesBenchmark';
import TelecomSubcategoriesBenchmarkAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-subcategories-benchmark/TelecomSubcategoriesBenchmarkAsset';
import Improvements from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/improvements/Improvements';
import ImprovementsAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/improvements/ImprovementsAsset';
import NextStoryPoll from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/NextStoryPoll';
import TelecomSummary
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-summary/TelecomSummary';
import TelecomSummaryAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/telecom-summary/TelecomSummaryAsset';
import * as _ from 'lodash';

export default {
  name: 'TelecomStory',
  components: {
    StoryViewer,
  },
  data() {
    return {
      pages: [],
    };
  },
  async created() {
    this.startLoading('midmonth');
    await this.fetchBenchmark();
    this.pages = this.generatePages();
    this.doneLoading('midmonth');
  },
  computed: {
    ...mapGetters('loader', ['appLoading']),
    ...mapGetters('session', ['householdName']),
    ...mapGetters('benchmark', ['telecomCategory', 'subcategories', 'top3Subcategories']),
    segmentExtraProps() {
      return {
        amount: this.telecomCategory.amount,
        subcategoriesCount: this.subcategories.length,
      };
    },
  },
  methods: {
    ...mapActions('loader', ['startLoading', 'doneLoading']),
    ...mapActions('benchmark', ['fetchBenchmark']),
    generatePages() {
      return _.compact([
        this.createWelcomeBenchmarkPage(),
        this.telecomCategory.amount === 0 ? this.createNoTelecomExpensesPage() : null,
        this.telecomCategory.amount > 0 ? this.createTelecomBenchmarkPage() : null,
        this.top3Subcategories.length > 0 ? this.createSubcategoriesBenchmarkPage() : null,
        this.telecomCategory.amount > 0 ? this.createImprovementsPage() : null,
        this.createNextStoryPollPage(),
        this.top3Subcategories.length > 0 ? this.createTelecomSummary() : null,
      ]);
    },
    createWelcomeBenchmarkPage() {
      return {
        component: Welcome,
        props: {},
        config: {
          hideFooter: true,
        },
      };
    },
    createNoTelecomExpensesPage() {
      return {
        component: NoTelecomExpenses,
        share: {
          component: NoTelecomExpensesAsset,
          props: {},
          segmentExtraProps: {
            page: 'NoTelecomExpenses',
          },
          filename: 'notelecom',
        },
      };
    },
    createTelecomBenchmarkPage() {
      return {
        component: TelecomBenchmark,
        props: {
          amount: this.telecomCategory.amount,
          low: this.telecomCategory.low,
          subcategories: this.subcategories,
        },
        share: {
          component: TelecomBenchmarkAsset,
          props: {
            amount: this.telecomCategory.amount,
            low: this.telecomCategory.low,
            subcategories: this.subcategories,
          },
          segmentExtraProps: {
            page: 'TelecomBenchmark',
            percentile: this.telecomCategory.low,
            amount: this.telecomCategory.amount,
            categoriesCount: this.subcategories.length,
          },
          filename: 'telecom',
        },
      };
    },
    createSubcategoriesBenchmarkPage() {
      return {
        component: TelecomSubcategoriesBenchmark,
        props: {
          top3Subcategories: this.top3Subcategories,
        },
        share: {
          component: TelecomSubcategoriesBenchmarkAsset,
          props: {
            top3Subcategories: this.top3Subcategories,
          },
          segmentExtraProps: {
            page: 'TelecomSubcategoriesBenchmark',
            lookingGoodCount: _.filter(this.top3Subcategories, ({ good }) => good).length,
            lookingBadCount: _.reject(this.top3Subcategories, ({ good }) => good).length,
          },
          filename: 'telecom-top3',
        },
      };
    },
    createImprovementsPage() {
      return {
        component: Improvements,
        props: {},
        share: {
          component: ImprovementsAsset,
          props: {},
          segmentExtraProps: {
            page: 'Improvements',
          },
          filename: 'improvements',
        },
      };
    },
    createNextStoryPollPage() {
      return {
        component: NextStoryPoll,
        props: {},
      };
    },
    createTelecomSummary() {
      return {
        component: TelecomSummary,
        props: {
          amount: this.telecomCategory.amount,
          low: this.telecomCategory.low,
          subcategories: this.subcategories,
          top3Subcategories: this.top3Subcategories,
          householdName: this.householdName,
        },
        share: {
          component: TelecomSummaryAsset,
          props: {
            amount: this.telecomCategory.amount,
            low: this.telecomCategory.low,
            subcategories: this.subcategories,
            top3Subcategories: this.top3Subcategories,
          },
          segmentExtraProps: {
            page: 'TelecomSummary',
          },
          filename: 'telecom-summary',
        },
      };
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.telecom-story {
  height: 100%;
}
</style>
