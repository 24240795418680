<template>
  <div class="price-tag">
    <div class="transparent-top-circle"></div>
    <div class="bar" :class="`color-${color}`"></div>
    <div class="amount">
      <div class="number">{{ formattedAmount }}</div>
      <div class="currency">₪</div>
    </div>
    <div class="bar" :class="`color-${color}`"></div>
    <div class="transparent-bottom-circle"></div>
  </div>
</template>

<script>

import moneyUtils from '@/utils/money';

export default {
  name: 'PriceTag',
  props: {
    amount: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amount, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.price-tag {
  position: relative;
  width: 130px;
  background: $riseup_gray_disabled;
  padding: 15px 3px;
  font-family: 'PauzaBold';
  font-size: 27px;
  line-height: 32px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

  .amount {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    .currency {
      font-size: 20px;
    }
  }

  .bar {
    height: 2px;
    border-radius: 2px;
    &.color-red {
      background: $riseup_light_red;
    }
    &.color-green {
      background: $riseup_light_green;
    }
  }

  .transparent-top-circle {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: $riseup_light_blue;
    right: calc(50% - 13px);
    top: -13px;
    box-shadow: inset 0px -7px 6px -9px rgb(0, 0, 0, 0.25);
  }

  .transparent-bottom-circle {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: $riseup_light_blue;
    right: calc(50% - 13px);
    bottom: -13px;
    box-shadow: inset 0px 7px 6px -6px rgb(0, 0, 0, 0.25);
  }
}
</style>
